/* stylelint-disable keyframe-selector-notation */
/* stylelint-disable property-no-vendor-prefix */
@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap';

:root {
  --azul: #4554e9;
  --celeste: #008cff;
  --celesteClaro: #e9f4fe;
  --negro: #242424;
  --gris: #878787;
  --grisClaro: #ebebeb;
  --verde: #3acc9d;
  --verdeBlanco: linear-gradient(277.37deg, #fff -0.05%, #3acc9d 72.29%);
  --blancoVerde: linear-gradient(277.37deg, #3acc9d -0.05%, #fff 108.29%);
  --titleBg: linear-gradient(277.6deg, #3acc9d 58.23%, rgb(58 204 157 / 0%) 124.79%);
  --violeta: #5c3fe1;
  --violetaCeleste: linear-gradient(0deg, #5c3fe1 22%, #008cff 84.57%);
  --celesteVioleta: linear-gradient(338.59deg, #008cff 32.51%, #5c3fe1 84.57%);
  --violetaVerde: linear-gradient(0deg, #5c3fe1 0%, #3acc9d 100.34%);
  --violetaVerdeVertical: linear-gradient(0deg, #5c3fe1 0%, #3acc9d 111.84%);
  --blancoCeleste: linear-gradient(1.24deg, #e9f4fe 4.17%, #fafafa 69.3%);
  --celesteBlancoCeleste: linear-gradient(29deg, #c5c5fa 0%, #fff 50%, #c5c5fa 100%);
  --faqsGradient: linear-gradient(0.97deg, #e9f4fe 0.83%, #fff 66.39%);
  --grisVariant: #e5e5e5;
  --rojoError: #ff5959;
  --borderHero: 0 60px;
  --borderContainer: 40px;
  --borderCard: 20px;
  --borderCardSmall: 10px;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Morebi Rounded', Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.safeSpace {
  margin: 3rem auto;
  max-width: 1512px;
  padding-left: 8rem;
  padding-right: 8rem;
}

@media screen and (max-width: 899px) {
  .safeSpace {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

a {
  color: inherit;
  text-decoration: inherit;
}

.wow {
  visibility: hidden;
}

.fadeInAnimation {
  animation-name: fade-in;
}

@keyframes fade-in {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.fadeInUpAnimation {
  animation-name: fade-in-up;
}

@keyframes fade-in-up {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fadeInDownAnimation {
  animation-name: fade-in-down;
}

@keyframes fade-in-down {
  from {
    transform: translateY(100px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
