@font-face {
  font-family: 'Morebi Rounded';
  src:
    url('MorebiRounded-Bold.woff2') format('woff2'),
    url('MorebiRounded-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Morebi Rounded';
  font-style: normal;
  font-weight: normal;
  src: local('Morebi Rounded Regular'), url('MorebiRounded-Regular.woff') format('woff');
}
